import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ImageWebp } from "../../controls/image-webp";
import { RootState } from "../../store/reducer";
import "./footer.css";
import "./footer.responsive.css";
import messages from "./messages";

const FooterView = () => {
  const { language } = useSelector((state: RootState) => state.language);

  return (
    <footer className="footer mt-auto py-4 row text-center text-white">
      <div>
        <ImageWebp
          className="footer-logo"
          src={`${process.env.PUBLIC_URL}/images/footer-logo.svg`}
          alt={messages[language].footerLogo}
        ></ImageWebp>
      </div>
      <div className="social py-4">
        <a
          href="tel:+27765745122"
          target="_blank"
          title={messages[language].cellphone}
          rel="noreferrer"
        >
          <ImageWebp
            className="rounded-circle"
            src={`${process.env.PUBLIC_URL}/images/contact/phone-light.svg`}
            alt={messages[language].cellphone}
          ></ImageWebp>
        </a>
        <Link
          to="//www.facebook.com/Cheetahelectrical"
          target="_blank"
          title={messages[language].facebookLogo}
          className="mx-3"
        >
          <ImageWebp
            src={`${process.env.PUBLIC_URL}/images/social/facebook.png`}
            srcWebp={`${process.env.PUBLIC_URL}/images/social/facebook.lossless.webp`}
            alt={messages[language].facebookLogo}
          ></ImageWebp>
        </Link>
        <a
          href="mailto:pierre@cheetah-ea.co.za"
          target="_blank"
          title={messages[language].email}
          rel="noreferrer"
        >
          <ImageWebp
            className="rounded-circle"
            src={`${process.env.PUBLIC_URL}/images/contact/email-light.svg`}
            alt={messages[language].email}
          ></ImageWebp>
        </a>
      </div>
      <div className="m-auto pb-4 pb-md-0 w-75">
        <p className="small">
          &copy;&nbsp;{new Date().getFullYear()}&nbsp;
          {messages[language].copyright}
        </p>
        <p
          className="text-white small"
          dangerouslySetInnerHTML={{ __html: messages[language].poweredBy }}
        ></p>
      </div>
    </footer>
  );
};

export { FooterView };

import { Language } from "../../store/language/language-slice";

interface Messages {
  home: string;
  electrical: string;
  automation: string;
  contact: string;
  cellphone: string;
  email: string;
  facebook: string;
  languages: {
    [key in Language]: string;
  };
}

type Message = {
  [key in Language]: Messages;
};

const messages: Message = {
  en: {
    home: "HOME",
    automation: "AUTOMATION",
    contact: "CONTACT US",
    electrical: "ELECTRICAL",
    cellphone: "Call Us",
    email: "Email Us",
    facebook: "Friend Us",
    languages: {
      af: "AF",
      en: "EN",
    },
  },
  af: {
    home: "TUIS",
    automation: "AUTOMASIE",
    contact: "KONTAK ONS",
    electrical: "ELEKTRIES",
    cellphone: "Bel Ons",
    email: "E-pos Ons",
    facebook: "Vriend Ons",
    languages: {
      af: "AF",
      en: "EN",
    },
  },
};

export default messages;

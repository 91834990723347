import React, { ReactEventHandler, useEffect, useState } from "react";
import {
  activateWebpCompatibility,
  transparentImage,
  webpCompatibilityInfo,
} from "../../utils/webp";
import { ImageWebpView } from "./image-webp-view";

const ImageWebp = ({
  src,
  srcWebp,
  ...props
}: {
  src: string;
  srcWebp?: string;
  className?: string;
  style?: object;
  width?: string | number;
  height?: string | number;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  onMouseMove?: ReactEventHandler<HTMLImageElement>;
  onMouseLeave?: ReactEventHandler<HTMLImageElement>;
  alt?: string;
}) => {
  const [webpCompatibilityState, setWebpCompatibilityState] = useState(
    webpCompatibilityInfo
  );
  const [actualSrc, setActualSrc] = useState(transparentImage);

  useEffect(() => {
    if (!webpCompatibilityState)
      activateWebpCompatibility().then((info) => {
        setWebpCompatibilityState(info);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!webpCompatibilityState) {
      setActualSrc(transparentImage);
    } else if (srcWebp) {
      const {
        ALL,
        NONE,
        lossless,
        alpha,
        lossy,
        animation,
      } = webpCompatibilityInfo;

      if (ALL) {
        setActualSrc(srcWebp);
      } else if (!NONE) {
        if (srcWebp.toLowerCase().endsWith(".alpha.webp")) {
          if (alpha) setActualSrc(srcWebp);
        } else if (srcWebp.toLowerCase().endsWith(".lossless.webp")) {
          if (lossless) setActualSrc(srcWebp);
        } else if (srcWebp.toLowerCase().endsWith(".animation.webp")) {
          if (animation) setActualSrc(srcWebp);
        } else if (lossy) setActualSrc(srcWebp);
      }
    } else {
      setActualSrc(src);
    }
  }, [webpCompatibilityState, src, srcWebp]);

  const onLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    const { onLoad } = props;
    if (onLoad && e.currentTarget.src !== transparentImage) onLoad(e);
  };

  const onMouseMove: ReactEventHandler<HTMLImageElement> = (e) => {
    const { onMouseMove } = props;
    if (onMouseMove && e.currentTarget.src !== transparentImage) onMouseMove(e);
  };

  const onMouseLeave: ReactEventHandler<HTMLImageElement> = (e) => {
    const { onMouseLeave } = props;
    if (onMouseLeave && e.currentTarget.src !== transparentImage)
      onMouseLeave(e);
  };

  return (
    <ImageWebpView
      {...props}
      src={actualSrc}
      onLoad={onLoad}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    ></ImageWebpView>
  );
};

export { ImageWebp };

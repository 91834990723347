const getWebpCompatibilityInfo = () =>
  JSON.parse(localStorage.getItem("browser-compatibilities")!);
const saveWebpCompatibilityInfo = (info: object) =>
  localStorage.setItem("browser-compatibilities", JSON.stringify(info));

const webpCompatibilityInfo = getWebpCompatibilityInfo() || { NONE: true };

export const transparentImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

const webpCompatibilityTest = () => {
  const webpTestImages: { [key: string]: string } = {
    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    alpha:
      "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    animation:
      "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
  };

  const webpTestImagesKeys = Object.keys(webpTestImages);
  let nCompatible = 0;

  var success: ((value: unknown) => void) | null = null;

  var result = new Promise((resolve) => {
    success = resolve;
  });

  var iterations = 0;

  const completeIteration = () => {
    iterations++;
    if (iterations === webpTestImagesKeys.length && success)
      success(webpCompatibilityInfo);
  };

  webpTestImagesKeys.forEach((type) => {
    const xqImg = new Image();
    xqImg.onload = () => {
      webpCompatibilityInfo[type] = xqImg.width > 0 && xqImg.height > 0;

      if (webpCompatibilityInfo[type]) {
        webpCompatibilityInfo.NONE = false;
        nCompatible += 1;

        if (nCompatible === webpTestImagesKeys.length)
          webpCompatibilityInfo.ALL = true;
      }

      saveWebpCompatibilityInfo(webpCompatibilityInfo);

      completeIteration();
    };
    xqImg.onerror = () => {
      webpCompatibilityInfo[type] = false;
      saveWebpCompatibilityInfo(webpCompatibilityInfo);

      completeIteration();
    };
    xqImg.src = `data:image/webp;base64,${webpTestImages[type]}`;
  });

  return result;
};

export const activateWebpCompatibility = () => {
  var response = getWebpCompatibilityInfo();

  if (!response) return webpCompatibilityTest();
  else return Promise.resolve(response);
};

export { webpCompatibilityInfo };

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ImageWebp } from "../../controls/image-webp";
import { Language } from "../../store/language/language-slice";
import { RootState } from "../../store/reducer";
import messages from "./messages";
import "./navbar.css";
import "./navbar.responsive.css";

const NavbarView = ({
  isScrollAtTop,
  changeLanguage,
}: {
  isScrollAtTop: boolean;
  changeLanguage: (language: Language) => void;
}) => {
  const { language } = useSelector((state: RootState) => state.language);

  const renderMenuItems = () => {
    return (
      <ul className="flex-grow-1 justify-content-end justify-content-evenly navbar-nav pe-3">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/#home">
            {messages[language].home}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/#electrical">
            {messages[language].electrical}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/#automation">
            {messages[language].automation}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/#contact">
            {messages[language].contact}
          </Link>
        </li>
      </ul>
    );
  };

  const renderSocial = () => {
    return (
      <>
        <a
          href="tel:+27765745122"
          target="_blank"
          className="mx-0 mx-lg-4"
          title={messages[language].cellphone}
          rel="noreferrer"
        >
          <ImageWebp
            className="bg-white box-shadow rounded-circle"
            src={`${process.env.PUBLIC_URL}/images/contact/phone.svg`}
            alt={messages[language].cellphone}
          ></ImageWebp>
        </a>
        <a
          href="mailto:pierre@cheetah-ea.co.za"
          target="_blank"
          title={messages[language].email}
          className="mx-0 mx-lg-4"
          rel="noreferrer"
        >
          <ImageWebp
            className="bg-white box-shadow rounded-circle"
            src={`${process.env.PUBLIC_URL}/images/contact/email.svg`}
            alt={messages[language].email}
          ></ImageWebp>
        </a>
        <a
          href="https://www.facebook.com/Cheetahelectrical"
          target="_blank"
          title={messages[language].facebook}
          className="mx-0 mx-lg-4"
          rel="noreferrer"
        >
          <ImageWebp
            className="bg-white box-shadow rounded-circle"
            src={`${process.env.PUBLIC_URL}/images/contact/facebook.svg`}
            alt={messages[language].facebook}
          ></ImageWebp>
        </a>
      </>
    );
  };

  const renderLanguage = (disabled?: boolean) => {
    var className = [
      "fw-bold mx-0 mx-lg-4 text-bold text-shadow",
      disabled ? "invisible" : "",
    ].join(" ");

    return (
      <>
        <span
          className={className}
          onClick={() => !disabled && changeLanguage("en")}
          role={!disabled ? "button" : undefined}
        >
          {messages[language].languages.en}
        </span>
        <span
          className={className}
          onClick={() => !disabled && changeLanguage("af")}
          role={!disabled ? "button" : undefined}
        >
          {messages[language].languages.af}
        </span>
      </>
    );
  };

  return (
    <div
      className={[
        "bg-white position-fixed row shadow-lg-none shadow-sm w-100 z-index-100 transition",
        isScrollAtTop ? "bg-lg-transparent" : "",
      ]
        .join(" ")
        .trim()}
    >
      <div
        className={[
          "container d-none d-lg-block text-center mb-lg-3 pt-4 animation-lg-fade-in",
          isScrollAtTop ? "" : "animation-lg-fade-out",
        ]
          .join(" ")
          .trim()}
      >
        <div className="row">
          <div className="col-auto">{renderLanguage(true)}</div>
          <div className="col social-navbar">{renderSocial()}</div>
          <div className="col-auto language-navbar">{renderLanguage()}</div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container p-0">
          <div className="align-items-center col d-flex d-lg-none justify-content-evenly">
            {renderSocial()}
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            data-bs-toggle="offcanvas"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end" id="navbarSupportedContent">
            <div className="offcanvas-header">
              <h5 className="d-flex d-lg-none justify-content-evenly nav-item offcanvas-title w-100">
                {renderLanguage()}
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">{renderMenuItems()}</div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export { NavbarView };

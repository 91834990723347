import { Action, configureStore } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { getCachedStore, setCachedStore } from "../helpers/cache";
import { DEFAULT_THEME, ThemeState } from "../styles/themes";
import rootReducer, { RootState } from "./reducer";
import { setTheme } from "./theme/theme-slice";

const preloadedState = getCachedStore();

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  devTools: true,
  enhancers: [],
});

store.subscribe(() => {
  const { language, theme } = store.getState();

  setCachedStore({
    language,
    theme,
  });
});

store.dispatch(setTheme(preloadedState?.theme?.theme || DEFAULT_THEME));

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export interface GlobalApplicationState {
  theme: ThemeState;
}

export default store;

import { Footer } from "../../components/footer";
import { Navbar } from "../../components/navbar";
import { ScrollOnLoad } from "../../components/scroll-on-load";

const PublicView = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <ScrollOnLoad></ScrollOnLoad>
      <div className="container-fluid d-flex flex-column h-100">
        <Navbar />
        <div className="main-body row position-relative z-index-0">
          {children}
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};

export { PublicView };

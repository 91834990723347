import React, { Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { CustomLoader } from "./components/custom-loader";
import routes, { Route as LocalRoute } from "./routes";
import store from "./store";
import { joinUrl } from "./utils/join-url";
import { activateWebpCompatibility } from "./utils/webp";
import { Public as PublicLayout } from "./wrappers/public";

function renderRoute(route: LocalRoute, parentPath = "") {
  const component = (
    <route.component>
      {route.routes && route.routes.map((r) => renderRoute(r, route.path))}
    </route.component>
  );

  const Wrapper = ({ children }: { children: React.ReactNode }) => (
    <>{children}</>
  );

  const Layout = (() => {
    if (parentPath)
      return ({ children }: { children: React.ReactNode }) => <>{children}</>;
    return PublicLayout;
  })();

  return (
    <Route
      key={route.path}
      path={joinUrl(parentPath, route.path)}
      sensitive={false}
      exact={route.exactMatch}
    >
      <Wrapper>
        <Layout>
          <Suspense fallback={<CustomLoader page />}>{component}</Suspense>
        </Layout>
      </Wrapper>
    </Route>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    activateWebpCompatibility().then((value) => {
      setLoading(false);
    });
  }, []);

  if (loading) return <CustomLoader page></CustomLoader>;

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {routes.map((route) => renderRoute(route))}
          <Route path="/404" />
          <Redirect from="*" to="/404" />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

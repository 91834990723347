import React, { useEffect } from "react";
import { useHistory } from "react-router";

const ScrollOnLoad = () => {
  var history = useHistory();

  const currentPositionY = () => {
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
    return supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
  };

  const scrollToHash = (hash: string) => {
    setTimeout(() => {
      if (hash) {
        const offset =
          (document.getElementById(hash.slice(1))?.getBoundingClientRect()
            .top || 0) + currentPositionY();

        window.scrollTo(0, offset);
      }
    }, 100);
  };

  history.listen(({ hash }) => {
    scrollToHash(hash);
  });

  useEffect(() => {
    scrollToHash(window.location.hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export { ScrollOnLoad };

import "./custom-loader.css";

const CustomLoaderView = ({ page }: { page?: boolean }) => {
  return (
    <div
      className={["spinner-border", page ? "spinner-full-page" : ""].join(" ")}
      role="status"
    >
      <span className="sr-only"></span>
    </div>
  );
};

export { CustomLoaderView };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Language = "en" | "af";

const initialState: {
  language: Language;
} = {
  language: "en",
};

const language = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;
      return state;
    },
  },
});

export const { setLanguage } = language.actions;

export default language.reducer;

import { useDispatch } from "react-redux";
import { changeLanguage as changeLanguageAction } from "../store/language/language-actions";
import { Language } from "../store/language/language-slice";

export const useChangeLanguage = () => {
  const dispatch = useDispatch();

  const changeLanguage = (value: Language) => {
    dispatch(changeLanguageAction(value));
  };

  return [changeLanguage];
};

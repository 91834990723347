import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "..";
import { applyTheme, DEFAULT_THEME, ThemeState } from "../../styles/themes";

const initialState: {
  theme: ThemeState;
} = {
  theme: DEFAULT_THEME,
};

const theme = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeAction(state, action: PayloadAction<ThemeState>) {
      state.theme = action.payload;
      return state;
    },
  },
});

export const { setThemeAction } = theme.actions;

export const setTheme = (themeState: ThemeState): AppThunk => (dispatch) => {
  try {
    dispatch(setThemeAction(themeState));
    applyTheme(themeState);
  } catch (err) {
    //
  }
};

export default theme.reducer;

import { RootState } from "../store/reducer";

const STORE_KEY = "cheetah-ea/store";

export function setCachedStore(store: any) {
  localStorage.setItem(STORE_KEY, JSON.stringify(store));
}

export function getCachedStore(): RootState {
  const storeString = localStorage.getItem(STORE_KEY);
  return storeString ? JSON.parse(storeString) : undefined;
}

export function removeCachedStore(): void {
  localStorage.setItem(STORE_KEY, JSON.stringify({}));
}

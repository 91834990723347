import { lazy } from "react";
import { Route } from ".";

const NotFoundView = lazy(() => import("../pages/not-found"));
const HomeView = lazy(() => import("../pages/home"));

/**
 * If a route is a sub-route of another, the parent must pass Fwn the child route via its
 * children property.
 */
const routes: Route[] = [
  {
    path: "/404",
    component: NotFoundView,
    public: true,
  },
  {
    path: "/",
    component: HomeView,
    public: true,
    exactMatch: true,
  },
];

export default routes;

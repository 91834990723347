import { DEFAULT_THEME, ThemeState } from ".";
import hyphenCase from "../../utils/hyphen-case";

type MappedTheme = {
  [key: string]: string | null;
};

export function mapTheme(variables: ThemeState): MappedTheme {
  return Object.keys(variables).reduce<{ [key: string]: string }>(
    (theme, key) => {
      theme[`--${hyphenCase(key)}`] = variables[key as keyof ThemeState];
      return theme;
    },
    {}
  );
}

export const applyTheme = (themeState: ThemeState): void => {
  const theme = mapTheme(themeState);
  if (!theme) return;

  const root = document.documentElement;

  Object.keys(theme).forEach((property) => {
    root.style.setProperty(property, theme[property] as string);
  });
};

export function applyDefaultTheme() {
  applyTheme(DEFAULT_THEME);
}

import React, { ReactEventHandler } from "react";

const ImageWebpView = ({
  src,
  className,
  style,
  width,
  height,
  alt,
  onLoad,
  onMouseLeave,
  onMouseMove,
}: {
  src: string;
  className?: string;
  style?: object;
  width?: string | number;
  height?: string | number;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  onMouseMove?: ReactEventHandler<HTMLImageElement>;
  onMouseLeave?: ReactEventHandler<HTMLImageElement>;
  alt?: string;
}) => {
  return (
    <img
      src={src}
      className={className}
      style={style}
      onLoad={onLoad}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      alt={alt}
      width={width}
      height={height}
    />
  );
};

export { ImageWebpView };

import React, { useEffect, useState } from "react";
import { useChangeLanguage } from "../../helpers/language";
import { NavbarView } from "./navbar-view";

const Navbar = () => {
  const [isScrollAtTop, setIsScrollAtTop] = useState(
    document.body.scrollTop < 80 || document.documentElement.scrollTop < 80
  );

  const [changeLanguage] = useChangeLanguage();

  const onScroll = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      setIsScrollAtTop(false);
    } else {
      setIsScrollAtTop(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  return (
    <NavbarView changeLanguage={changeLanguage} isScrollAtTop={isScrollAtTop} />
  );
};

export { Navbar };

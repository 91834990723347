import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import "./index.animation.css";
import "./index.css";
import "./index.responsive.css";
import reportWebVitals from "./reportWebVitals";

const reCaptchaKey = "6LfrfoUcAAAAAP-dHduTh63xLrQSGYjAp1QFGJZ6";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={reCaptchaKey}
        scriptProps={{
          async: true,
          defer: true,
        }}
      >
        <App />
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Language } from "../../store/language/language-slice";

interface Messages {
  footerLogo: string;
  cellphone: string;
  facebookLogo: string;
  email: string;
  copyright: string;
  poweredBy: string;
}

type Link = {
  [key in Language]: Messages;
};

const links: Link = {
  en: {
    footerLogo: "Cheetah Electrical and Automation footer logo",
    facebookLogo: "Friend Us",
    cellphone: "Call Us",
    email: "Email Us",
    copyright: "Cheetah Electrical and Automation",
    poweredBy:
      "Designed by <a href='https://www.vrdstudio.com/' target='_blank'>VRD Studios</a> and powered by <a href='https://dev200.co.za' target='_blank'>Dev200</a>",
  },
  af: {
    footerLogo: "Cheetah Electrical and Automation voettekst logo",
    facebookLogo: "Vriend Ons",
    cellphone: "Bel Ons",
    email: "E-pos Ons",
    copyright: "Cheetah Electrical and Automation",
    poweredBy:
      "Ontwerp deur <a href='https://www.vrdstudio.com/' target='_blank'>VRD Studios</a> en aangedryf deur <a href='https://dev200.co.za' target='_blank'>Dev200</a>",
  },
};

export default links;

/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import languageReducer from "./language/language-slice";
import themeReducer from "./theme/theme-slice";

const combinedReducers = combineReducers({
  theme: themeReducer,
  language: languageReducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = {
      theme: state.theme,
    };
  }
  return combinedReducers(state, action);
};

export default rootReducer;
